import tw, { styled } from 'twin.macro';

type Props = {
  center?: boolean;
  around?: boolean;
  start?: boolean;
  reverse?: boolean;
};

export const FlexContainer = styled.div<Props>`
  ${tw`flex flex-row flex-wrap justify-between`}
  ${tw`md:flex-nowrap md:pb-8`}
  ${({ center }) => center && tw`md:justify-center`}
  ${({ around }) => around && tw`md:justify-around`}
  ${({ start }) => start && tw`md:justify-start`}
  ${({ reverse }) => reverse && tw`flex-wrap-reverse`}
`;
