import tw, { styled } from 'twin.macro';

type Props = {
  pt0?: boolean;
  pb0?: boolean;
};

export const PageSection = styled.section<Props>`
  ${tw`w-full px-4 py-4 md:px-16 md:py-4`}
  ${({ pt0 }) => pt0 && tw`md:pb-0`}
  ${({ pb0 }) => pb0 && tw`md:pb-0`}
`;
