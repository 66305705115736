import tw, { styled } from 'twin.macro';

type Props = {
  center?: boolean;
  medium?: boolean;
  normal?: boolean;
};

export const SectionTitle = styled.h1<Props>`
  ${tw`font-heading font-medium text-gray-900 text-xl text-center uppercase pb-4`}
  ${tw`md:text-left`}
  ${({ center }) => center && tw`md:text-center`}
  ${({ medium }) => medium && tw`md:font-medium`}
  ${({ normal }) => normal && tw`md:font-normal`}
`;
