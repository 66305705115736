import tw, { styled } from 'twin.macro';

type Props = {
  w13?: boolean;
  w12?: boolean;
  w23?: boolean;
  wfull?: boolean;
};

export const TextContainer = styled.div<Props>`
  ${tw`w-full mb-12 text-lg`}
  ${({ w13 }) => w13 && tw`md:w-1/3`}
  ${({ w12 }) => w12 && tw`md:w-1/2`}
  ${({ w23 }) => w23 && tw`md:w-2/3`}
  ${({ wfull }) => wfull && tw`md:w-full`}
`;
